import React from "react";
import { ReactComponent as ApprovedSvg } from "../../assets/svg/approved.svg";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../helpers/http.helper";

const EscortCard = ({ ad }) => {
  const navigate = useNavigate();

  const modifiedName = ad.name.replace(/ /g, "-").toLowerCase();

  return (
    <article onClick={() => navigate(`/escort/${modifiedName}`)} className="escort-card user">
      <img src={`${BASE_URL}/img/${ad.mainImage}`} alt="images" width="100px" height="100px" />
      <div className="personal-details">
        <div>
          <span>{ad.name}</span>
          <ApprovedSvg />
        </div>
        <span>
          <span>{ad.nationality}</span> - <span>{ad.age} Years</span>
        </span>
      </div>

      {/* <div className="description">{ad.description}</div> */}
    </article>
  );
};

export default EscortCard;
