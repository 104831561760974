import React from "react";
import { http } from "../helpers/http.helper";
import CommonTopBar from "../components/layouts/CommonTopBar";
import { Helmet } from "react-helmet";
import { LogoImage } from "../assets/images";
import FooterSection from "../components/layouts/FooterSection";
import Navigation from "../components/layouts/Navigation";

import { ReactComponent as SearchSvg } from "../assets/svg/search.svg";
import AgencyViewCard from "../components/cards/AgencyViewCard";

const Agencies = () => {
  const [agencies, setAgencies] = React.useState([]);

  const getAds = React.useCallback(async () => {
    try {
      const { data } = await http.get("/pub/get-agency-list");
      setAgencies(data.agencies);
      console.log(data.agencies);
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    getAds();
  }, [getAds]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> {`Luxury Linkup - Agencies`} </title>
        <meta name="title" content={`Luxury Linkup - Agencies`} />

        {/* <!-- Open Graph Meta Tags --> */}
        <meta property="og:title" content={`Luxury Linkup - Agencies`} />

        <meta property="og:image" content={LogoImage} />
        <meta property="og:url" content={`https://luxurylinkup.com/agencies`} />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Luxury Linkup - Agencies`} />

        <meta name="twitter:image" content={LogoImage} />
        <meta name="twitter:url" content={`https://luxurylinkup.com/agencies`} />

        <link rel="canonical" href={`https://luxurylinkup.com/agencies`} />
      </Helmet>
      <CommonTopBar selectedCountry="Agencies" />
      <main>
        <div className="main-content">
          <div className="main-content-title-wrapper without-filters">
            <div className="main-content-title-container">
              <h1 className="selected-country-name">Agencies</h1>
              <Navigation />
            </div>
            <div className="search-bar-agency">
              <input type="text" className="agency-search" placeholder="Search agency name" />
              <SearchSvg />
            </div>
          </div>
          {/* <h1 className="page-titles">Top 50 Escorts</h1> */}
          <div className="escort-card-wrapper">
            <div className="escort-card-container">
              {agencies.map((agency) => (
                <AgencyViewCard key={agency._id} {...agency} />
              ))}
            </div>
          </div>

          <FooterSection />
        </div>
      </main>
    </>
  );
};

export default Agencies;
