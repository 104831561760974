import React from "react";
import { ReactComponent as LeftSvg } from "../../assets/svg/arrow-left.svg";
import { useNavigate, useParams } from "react-router-dom";
import { protectedHttp } from "../../helpers/http.helper";
import { ReactComponent as RemoveSvg } from "../../assets/svg/remove.svg";

const EditLink = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [text, setText] = React.useState("");
  const [metaTitle, setMetaTitle] = React.useState("");
  const [metaDescription, setMetaDescription] = React.useState("");
  const [metaKeywords, setMetaKeywords] = React.useState("");
  const [links, setLinks] = React.useState([]);
  const [heading2, setHeading2] = React.useState("");
  const [heading3, setHeading3] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [linkText, setLinkText] = React.useState("");
  const [linkUrl, setLinkUrl] = React.useState("");

  const getLink = React.useCallback(() => {
    protectedHttp
      .get(`/admin/get-link/${params.city}`)
      .then((res) => {
        const link = res.data.link;
        setText(link.text);
        setMetaTitle(link.metaTitle);
        setMetaDescription(link.metaDescription);
        setMetaKeywords(link.metaKeywords);
        link.heading2 && setHeading2(link.heading2);
        link.heading3 && setHeading3(link.heading3);
        link.links && setLinks(link.links);
        link.heading3 && setDescription(link.description);
      })
      .catch((err) => console.log(err.response.data.msg));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateLink = (e) => {
    e.preventDefault();

    // make text lowercase and replace space with -
    const updatedText = text.toLowerCase().replace(/ /g, "-");

    protectedHttp
      .patch(`/admin/update-link/${params.city}`, {
        text: updatedText,
        metaTitle,
        metaDescription,
        metaKeywords,
        heading2,
        heading3,
        links,
        description,
      })
      .then(() => {
        alert("Link updated successfully");
        getLink();
      })
      .catch((err) => console.log(err.response.data.msg));
  };

  React.useEffect(() => {
    getLink();
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, []);

  const addLinkToArr = () => {
    if (!linkText || !linkUrl) return alert("Please fill in all the fields");

    setLinks([...links, { text: linkText, url: linkUrl }]);
    setLinkText("");
    setLinkUrl("");
  };

  const deleteLink = (index) => {
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
  };

  return (
    <>
      <main>
        <div className="main-content">
          <div className="admin-top-bar-wrapper">
            <div className="admin-top-bar-container with-back">
              <button onClick={() => navigate(-1)}>
                <LeftSvg />
              </button>
              <h3>Edit Link</h3>
            </div>
          </div>
          <div className="escort-card-wrapper">
            {/* basic stuff comes here */}
            <div className="form-container admin-add-form">
              <form onSubmit={updateLink}>
                <div className="right">
                  <div className="row column-2">
                    <div className="input-wrapper">
                      <input type="text" placeholder="" id="city" disabled value={params.city} />
                      <label htmlFor="city" className="placeHolderLabel">
                        City
                      </label>
                    </div>
                    <div className="input-wrapper">
                      <input
                        onChange={(e) => setText(e.target.value)}
                        type="text"
                        placeholder=" "
                        id="link"
                        value={text}
                      />
                      <label htmlFor="link" className="placeHolderLabel">
                        Text
                      </label>
                    </div>
                  </div>
                  <div className="row column-2">
                    <div className="input-wrapper">
                      <input
                        onChange={(e) => setMetaTitle(e.target.value)}
                        type="text"
                        placeholder=" "
                        id="metatitle"
                        value={metaTitle}
                      />
                      <label htmlFor="metatitle" className="placeHolderLabel">
                        Meta title
                      </label>
                    </div>
                    <div className="input-wrapper">
                      <input
                        onChange={(e) => setMetaKeywords(e.target.value)}
                        type="text"
                        placeholder=" "
                        id="metakeywords"
                        value={metaKeywords}
                      />
                      <label htmlFor="metakeywords" className="placeHolderLabel">
                        Meta Keywords
                      </label>
                    </div>
                  </div>
                  <div className="row column-1">
                    <div className="input-wrapper with-textarea">
                      <textarea
                        onChange={(e) => setMetaDescription(e.target.value)}
                        placeholder=" "
                        name=""
                        id="metadescription"
                        cols="30"
                        rows="5"
                        value={metaDescription}
                      />
                      <label htmlFor="name" className="placeHolderLabel">
                        Meta Description
                      </label>
                    </div>
                  </div>
                </div>

                <div className="right">
                  <label htmlFor="">Titles</label>
                  <div className="row column-2">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        placeholder=" "
                        id="metatitle"
                        onChange={(e) => setHeading2(e.target.value)}
                        value={heading2}
                      />
                      <label htmlFor="metatitle" className="placeHolderLabel">
                        H2
                      </label>
                    </div>
                    <div className="input-wrapper">
                      <input
                        type="text"
                        placeholder=" "
                        id="metakeywords"
                        onChange={(e) => setHeading3(e.target.value)}
                        value={heading3}
                      />
                      <label htmlFor="metakeywords" className="placeHolderLabel">
                        H3
                      </label>
                    </div>
                  </div>

                  <label htmlFor="">Links</label>
                  <div className="row column-2">
                    <div className="input-wrapper">
                      <input
                        onChange={(e) => setLinkText(e.target.value)}
                        type="text"
                        placeholder=""
                        id="city"
                        value={linkText}
                      />
                      <label htmlFor="city" className="placeHolderLabel">
                        Text
                      </label>
                    </div>
                    <div className="input-wrapper">
                      <input
                        onChange={(e) => setLinkUrl(e.target.value)}
                        type="text"
                        placeholder=" "
                        id="link"
                        value={linkUrl}
                      />
                      <label htmlFor="link" className="placeHolderLabel">
                        URL
                      </label>
                    </div>

                    <button type="button" onClick={addLinkToArr} className="add-new-row">
                      Add
                    </button>
                  </div>

                  {links.length > 0 &&
                    links.map((link, _) => (
                      <div key={link} className="row column-2">
                        <div className="input-wrapper">
                          <input type="text" placeholder="" id="city" value={link.text} disabled />
                          <label htmlFor="city" className="placeHolderLabel">
                            Text
                          </label>
                        </div>
                        <div className="input-wrapper">
                          <input type="text" placeholder=" " id="link" value={link.url} disabled />
                          <label htmlFor="link" className="placeHolderLabel">
                            URL
                          </label>
                        </div>

                        <button type="button" onClick={() => deleteLink(_)} className="add-new-row">
                          <RemoveSvg />
                        </button>
                      </div>
                    ))}

                  <label htmlFor="">Description</label>
                  <div className="row column-1">
                    <div className="input-wrapper with-textarea">
                      <textarea
                        placeholder=" "
                        name=""
                        id="metadescription"
                        cols="30"
                        rows="5"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                      />
                      <label htmlFor="name" className="placeHolderLabel">
                        Description
                      </label>
                    </div>
                  </div>
                </div>

                <button type="submit" className="input-wrapper btn">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EditLink;
