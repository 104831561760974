import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as DropDownSvg } from "../../assets/svg/dropdown.svg";

const Dropdown = ({ data, handler, value, placeholder, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [filteredData, setFilteredData] = useState([]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleFilterData = (e) => {
    if (e.target.value.toLowerCase() !== "") {
      setFilteredData(
        data.filter((record) => record.toLowerCase().includes(e.target.value))
      );
    } else {
      setFilteredData([]);
    }
  };

  return (
    <div
      className={`input-wrapper drop-down-wrappper ${className}`}
      ref={dropdownRef}
    >
      <div
        className={`drop-down-label ${
          value !== "" && "place-holder-replacement-needed"
        }`}
        onClick={toggleDropdown}
      >
        {value !== "" && (
          <label className="place-holder-replacement">{placeholder}</label>
        )}
        <label>{value !== "" ? value : placeholder}</label>
        <i className="svg-wrapper">
          <DropDownSvg />
        </i>
      </div>
      {isOpen && (
        <ul className="drop-down-container">
          <div className="search-dropdown-container">
            <input
              onChange={handleFilterData}
              type="text"
              placeholder="Search.."
            />
          </div>
          {filteredData.length > 0
            ? filteredData.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    handler(item);
                    setIsOpen(false);
                  }}
                >
                  {item}
                </li>
              ))
            : data.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    handler(item);
                    setIsOpen(false);
                  }}
                >
                  {item}
                </li>
              ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
