import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const FooterSection = ({ selectedLink, links }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const condition =
    location.pathname !== "/top-50" &&
    location.pathname !== "/porn-stars" &&
    location.pathname !== "/agencies" &&
    location.pathname !== "/about" &&
    location.pathname !== "/privacy-policy" &&
    location.pathname !== "/partners" &&
    location.pathname !== "/terms-conditions";

  return (
    <div className="footer">
      <div className="unique-content">
        <h2>{selectedLink && selectedLink.heading2 && selectedLink.heading2}</h2>
        <ul>
          {selectedLink &&
            selectedLink.links &&
            selectedLink.links.map((link) => (
              <li key={link._id}>
                <a href={`${link.url}`}>{link.text}</a>
              </li>
            ))}
        </ul>

        <h3>{selectedLink && selectedLink.heading3 && selectedLink.heading3}</h3>

        <p>{selectedLink && selectedLink.description && selectedLink.description}</p>
      </div>
      <ul>
        <li
          onClick={() => navigate(`/${localStorage.getItem("selectedLink")}`)}
          className={location.pathname.startsWith("/") && condition && "active"}
        >
          Escorts
        </li>
        <li
          onClick={() => {
            navigate("/top-50");
          }}
          className={location.pathname === "/top-50" && "active"}
        >
          Top 50 Escorts
        </li>
        <li
          onClick={() => {
            navigate("/porn-stars");
          }}
          className={location.pathname === "/porn-stars" && "active"}
        >
          Pornstar Escorts
        </li>
        <li
          onClick={() => {
            navigate("/agencies");
          }}
          className={location.pathname === "/agencies" && "active"}
        >
          Agencies
        </li>
        <li
          onClick={() => {
            navigate("/about");
          }}
          className={location.pathname === "/about" && "active"}
        >
          About Us
        </li>
        <li
          onClick={() => {
            navigate("/partners");
          }}
          className={location.pathname === "/partners" && "active"}
        >
          Partners
        </li>
        <li
          onClick={() => {
            navigate("/privacy-policy");
          }}
          className={location.pathname === "/privacy-policy" && "active"}
        >
          Privacy Policy
        </li>
        <li
          onClick={() => {
            navigate("/terms-conditions");
          }}
          className={location.pathname === "/terms-conditions" && "active"}
        >
          Terms & Conditions
        </li>
        <li
          onClick={() => {
            window.location.href = "mailto:info@luxurylinkup.com";
          }}
        >
          Contact Us
        </li>
        <li
          onClick={() => {
            window.location.href = "https://blog.luxurylinkup.com";
          }}
        >
          Blog
        </li>
      </ul>

      {links && (
        <ul className="countries">
          {links.map((link) => (
            <li key={link._id}>
              <a href={`https://luxurylinkup.com/${link.text}`}>{link.city}</a>
            </li>
          ))}
        </ul>
      )}

      <p>
        This website only allows adult individuals to advertise their time and companionship to
        other adult individuals. We do not provide a booking service nor arrange meetings. Any price
        indicated relates to time only and nothing else. Any service offered or whatever else that
        may occur is the choice of consenting adults and a private matter between them. In some
        countries, individuals do not legally have the choice to decide this; it is your
        responsibility to comply with local laws.
      </p>
      <span>© 2024 Luxury linkup</span>
      <span className="version">v{process.env.REACT_APP_VERSION}</span>
    </div>
  );
};

export default FooterSection;
