export const ageArray = ["18-20", "20-25", "25-30", "30-40", "40-60", "60-80"];

export const rateArray = [
  "0-500",
  "500-1000",
  "1000-1500",
  "1500-2000",
  "2000-3000",
  "3000-5000",
  "5000-10000",
];

export const countryArray = [
  "Dubai",
  "London",
  "St trope",
  "Monaco",
  "Mykonos",
  "Ibiza",
  "Madrid",
  "Barcelona",
  "Paris",
  "Nice",
  "South of France",
  "Marbella",
];

// export const servicesArray = [
//   "69 position",
//   "Anal",
//   "Bondage",
//   "Classic vaginal sex",
//   "Couples",
//   "Cum in face",
//   "Cum in mouth",
//   "Cum on body",
//   "Cunnilingus",
//   "Deepthroat",
//   "Dirty talk",
//   "Domination",
//   "Duo with girl",
//   "Erotic massage",
//   "Foot fetish",
//   "French kissing",
//   "GFE",
//   "Golden shower give",
//   "Golden shower receive",
//   "Group sex",
//   "Masturbation",
//   "Oral without condom",
//   "Rimming active",
//   "Rimming passive",
//   "Role-play",
// ];

export const servicesArray = [
  "Anal",
  "AWO",
  "BDSM/Bondage",
  "Bisexual",
  "BJ",
  "Body to Body",
  "CIM",
  "DP",
  "GFE",
  "Extra balls",
  "Fisting",
  "Foot Fetish",
  "Full Service",
  "Gang Bang",
  "Golden Shower",
  "HJ",
  "Incall",
];

export const ethnicities = [
  "Arabian",
  "Asian",
  "Ebony",
  "Caucasian",
  "Hispanic",
  "Indian",
  "Latin",
  "Mongolia",
  "Mixed race",
];

export const nationalities = [
  "Albanian",
  "American",
  "Argentinian",
  "Armenian",
  "Australian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladesh",
  "Barbados",
  "Belarussian",
  "Belgian",
  "Belizean",
  "Bolivian",
  "Brazilian",
  "British",
  "Bulgarian",
  "Cameroon",
  "Canadian",
  "Chilean",
  "Chinese",
  "Colombian",
  "Congolese",
  "Costa Rica",
  "Croatian",
  "Cuban",
  "Cyprus",
  "Czech",
  "Danish",
  "Dominican",
  "Dutch",
  "Ecuadorian",
  "Egyptian",
  "Emiratis",
  "English",
  "Estonian",
  "Ethiopian",
  "Filipino",
  "Finnish",
  "French",
  "Georgian",
  "German",
  "Ghanaian",
  "Greek",
  "Hondurasian",
  "Hungarian",
  "Indian",
  "Indonesian",
  "Iranian",
  "Irish",
  "Israeli",
  "Italian",
  "Ivorian",
  "Jamaican",
  "Japanese",
  "Kazakh",
  "Kenyan",
  "Korean",
  "Kuwaiti",
  "Lao",
  "Latvian",
  "Lebanese",
  "Lithuanian",
  "Luxembourger",
  "Malaysian",
  "Maltese",
  "Mexican",
  "Moldovan",
  "Mongolian",
  "Montenegrian",
  "Moroccan",
  "Mozambican",
  "Nepali",
  "New Zealander",
  "Nigerian",
  "Norwegian",
  "Omani",
  "Pakistan",
  "Panama",
  "Paraguayan",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Puerto Rican",
  "Romanian",
  "Russian",
  "Saudi",
  "Serbian",
  "Singaporean",
  "Slovak",
  "Slovenian",
  "South African",
  "Spanish",
  "Swedish",
  "Swiss",
  "Taiwanese",
  "Tanzanian",
  "Thai",
  "Trinidadian",
  "Tunisian",
  "Turkish",
  "Ugandan",
  "Ukrainian",
  "Uruguayan",
  "Venezuelan",
  "Vietnamese",
];
