import React, { useState } from "react";
import { ReactComponent as HamburgerSvg } from "../../assets/svg/hamburger.svg";
import { ReactComponent as ProfileSvg } from "../../assets/svg/profile.svg";

import { LogoImage } from "../../assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import { http, protectedHttp } from "../../helpers/http.helper";

const TopBar = ({ setAds, ads }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openAuth, setOpenAuth] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginUser = async (e) => {
    e.preventDefault();
    try {
      const res = await http.post("/auth/login", { email, password });
      localStorage.setItem("uu_session", JSON.stringify(res.data.token));
      window.location.reload();
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  const logoutClient = async () => {
    if (window.confirm("Are you sure to logout?")) {
      protectedHttp
        .post("/auth/signout")
        .then(() => {
          localStorage.removeItem("uu_session");
          navigate("/");
        })
        .catch((err) => console.log(err.response.data.msg));
    }
  };

  return (
    <div className="nav-bar-wrapper">
      <div className="nav-bar-container">
        <img
          src={LogoImage}
          alt="Logo EscortHub"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />

        {/* <button  className="menu-toggle">
        <i className="svg-wrapper">
          <HamburgerSvg />
        </i>
      </button> */}

        {/* <button className="country-picker-toggle">
        <i className="svg-wrapper">
          <LocationSvg />
        </i>
      </button> */}

        <button className="profile-toggle menu-toggle" onClick={() => setOpenMenu(!openMenu)}>
          <i className="svg-wrapper">
            <HamburgerSvg />
          </i>
        </button>

        <div className="auth-wrapper menu-items" style={{ display: openMenu ? "grid" : "none" }}>
          <span className="glasspane" onClick={() => setOpenMenu(!openMenu)}></span>
          <div>
            <nav className="login-wrapper navigation">
              <li
                onClick={() => navigate("/admin/dashboard")}
                className={location.pathname === "/admin/dashboard" && "active"}
              >
                Dashboard
              </li>
              <li
                onClick={() => navigate("/admin/my-ads")}
                className={location.pathname === "/admin/my-ads" && "active"}
              >
                My Ads
              </li>
            </nav>
            <nav className="login-wrapper navigation">
              <li onClick={() => navigate("/")} className={location.pathname === "/" && "active"}>
                Escorts
              </li>
              <li
                onClick={() => navigate("/top-50")}
                className={location.pathname === "/top-50" && "active"}
              >
                Top 50 Escorts
              </li>
              <li
                onClick={() => navigate("/porn-stars")}
                className={location.pathname === "/porn-stars" && "active"}
              >
                Pornstar Escorts
              </li>
            </nav>
          </div>
        </div>

        <div className="auth-wrapper" style={{ display: openAuth ? "grid" : "none" }}>
          <span className="glasspane" onClick={() => setOpenAuth(!openAuth)}></span>
          <div>
            {/* <button onClick={() => navigate("/auth/signup")}>Create an account ? </button> */}

            <div className="login-wrapper">
              {localStorage.getItem("uu_session") ? (
                <>
                  <button
                    style={{ border: "1px solid var(--primary-border)" }}
                    onClick={() => navigate("/admin")}
                  >
                    Dashboard
                  </button>
                  <button style={{ color: "red" }} onClick={logoutClient}>
                    Logout
                  </button>
                </>
              ) : (
                <form onSubmit={loginUser} style={{ display: "contents" }}>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    placeholder="email"
                    value={email}
                    style={{ width: "200px" }}
                  />
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="password"
                    value={password}
                  />
                  <button type="submit">Login</button>
                </form>
              )}
            </div>
          </div>
        </div>

        <div
          className="auth-wrapper filters-popup"
          style={{ display: openFilters ? "grid" : "none" }}
        >
          <span className="glasspane" onClick={() => setOpenFilters(!openFilters)}></span>
          <div>
            <h1>Search</h1>
          </div>
        </div>

        <div className="top-bar-menu-buttons">
          {/* {location.pathname === "/" && (
            <button
              className="profile-toggle"
              onClick={() => setOpenFilters(!openFilters)}
            >
              <i className="svg-wrapper">
                <SearchSvg />
              </i>
            </button>
          )} */}

          {localStorage.getItem("uu_session") ? (
            <button onClick={() => navigate("/admin/create")}>Dashboard</button>
          ) : (
            <button className="profile-toggle middle-button" onClick={() => setOpenAuth(!openAuth)}>
              <i className="svg-wrapper">
                <ProfileSvg />
              </i>
            </button>
          )}

          {/* <button
            className="profile-toggle"
            onClick={() => setOpenAuth(!openAuth)}
          >
            <i className="svg-wrapper">
              <HamburgerSvg />
            </i>
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
