import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as DropDownSvg } from "../../assets/svg/dropdown.svg";

const DropdownWithCheckbox = ({
  data,
  Customclass,
  placeholder,
  setSelectedArrayHandler,
  selectedArray,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [filteredData, setFilteredData] = useState([]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleArray = (item) => {
    const existingItemIndex = selectedArray.findIndex(
      (arrItem) => arrItem === item
    );

    if (existingItemIndex !== -1) {
      const updatedArray = [...selectedArray];
      updatedArray.splice(existingItemIndex, 1);
      setSelectedArrayHandler(updatedArray);
    } else {
      setSelectedArrayHandler([...selectedArray, item]);
    }
  };

  const handleFilterData = (e) => {
    if (e.target.value.toLowerCase() !== "") {
      setFilteredData(
        data.filter((record) => record.toLowerCase().includes(e.target.value))
      );
    } else {
      setFilteredData([]);
    }
  };

  return (
    <div className="input-wrapper drop-down-wrappper" ref={dropdownRef}>
      <div className="drop-down-label" onClick={toggleDropdown}>
        <label>{placeholder}</label>
        <i className="svg-wrapper">
          <DropDownSvg />
        </i>
      </div>
      {isOpen && (
        <ul className={`drop-down-container with-checkboxes ${Customclass}`}>
          <div className="search-dropdown-container">
            <input
              type="text"
              placeholder="Search.."
              onChange={handleFilterData}
            />
          </div>
          {filteredData.length > 0
            ? filteredData.map((item, index) => (
                <li key={item}>
                  <div className="input-wrapper check-box">
                    <label key={index}>
                      <input
                        type="checkbox"
                        onChange={() => handleArray(item)}
                        checked={selectedArray.includes(item)}
                      />
                      {item}
                    </label>
                  </div>
                </li>
              ))
            : data.map((item, index) => (
                <li key={item}>
                  <div className="input-wrapper check-box">
                    <label key={index}>
                      <input
                        type="checkbox"
                        onChange={() => handleArray(item)}
                        checked={selectedArray.includes(item)}
                      />
                      {item}
                    </label>
                  </div>
                </li>
              ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownWithCheckbox;
