import React from "react";
import { protectedHttp } from "../../helpers/http.helper";
import AdCard from "../../components/cards/AdCard";
import { ReactComponent as PlusSvg } from "../../assets/svg/plus.svg";
import { useNavigate } from "react-router-dom";

const AllAds = () => {
  const [ads, setAds] = React.useState([]);

  const navigate = useNavigate();

  const getAds = React.useCallback(async () => {
    try {
      const { data } = await protectedHttp.get("/admin/ad");
      setAds(data.ads);
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    getAds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatus = async (ad, status) => {
    const updatedAds = ads.map((a) => {
      if (a._id === ad._id) {
        a.status = status;
      }
      return a;
    });

    await protectedHttp.patch(`/admin/ad/change-status/${ad._id}`);

    setAds(updatedAds);
  };

  const deleteAd = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      protectedHttp
        .delete(`/admin/ad/${id}`)
        .then(() => {
          getAds();
        })
        .catch((err) => console.log(err.response.data.msg));
    }
  };

  return (
    <>
      <main>
        <div className="main-content">
          <div className="admin-top-bar-wrapper">
            <div className="admin-top-bar-container">
              <h1>All Ads</h1>

              {/* <button onClick={() => navigate("/admin/create")}>
                Create Ad <PlusSvg />
              </button> */}
            </div>
          </div>
          <div className="listing-table-wrapper listings">
            <div className="listing-table">
              <div className="listing-table-row listing-table-header">
                <div className="td-picture">Picture</div>
                <div className="td-name">Name</div>
                <div className="td-status">Status</div>
                <div className="td-actions">Actions</div>
              </div>

              {ads.map((ad) => (
                <AdCard
                  key={ad._id}
                  ad={ad}
                  handleStatus={handleStatus}
                  updateHandler={() => navigate(`/admin/update/${ad._id}`)}
                  deleteHandler={() => deleteAd(ad._id)}
                />
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AllAds;
