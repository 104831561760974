import React, { useRef, useState } from "react";
import { DropdownWithCheckbox } from "../../components";
import { ReactComponent as UserSvg } from "../../assets/svg/auth-user.svg";
import { ReactComponent as AddImageSvg } from "../../assets/svg/addimage.svg";
import { ReactComponent as WhatsAppSvg } from "../../assets/svg/whats-app.svg";
import { ReactComponent as TelegramSvg } from "../../assets/svg/telegram.svg";
import { ReactComponent as RemoveSvg } from "../../assets/svg/remove.svg";
import { ReactComponent as LeftSvg } from "../../assets/svg/arrow-left.svg";

import Dropdown from "../../components/elements/Dropdown";
import CountryCode from "../../components/elements/CountryCode";
import { countryArray, servicesArray } from "../../utils/data";
import { protectedMediaHttp } from "../../helpers/http.helper";
import {
  ageArr,
  availabilityArr,
  countryCodes,
  ethnicitiesArr,
  languagesArr,
  nationalitiesArr,
  sexualOrientationsArr,
} from "../../assets/data";
import { useNavigate } from "react-router-dom";

const AddAdvertisement = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [country, setCountry] = useState("Dubai");
  const [description, setDescription] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [nationality, setNationality] = useState("");
  const [sex, setSex] = useState("");
  const [age, setAge] = useState("");
  const [availability, setAvailability] = useState("");
  const [website, setWebsite] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [rate, setRate] = useState({ hours: "", amount: "" });
  const [rates, setRates] = useState([]);
  const [isOnWhatsApp, setIsOnWhatsApp] = useState(false);
  const [isOnTelegram, setIsOnTelegram] = useState(false);
  const [isPornStar, setIsPornStar] = useState(false);
  const [height, setHeight] = useState("");

  //drop down with checkboxes
  const [selectedLanguages, setSelectedLanguages] = React.useState([]);
  const [selectedServices, setSelectedServices] = React.useState([]);

  // images
  const mainImageRef = useRef(null);
  const fileInputRef = useRef(null);
  const [mainImage, setMainImage] = useState(null);
  const [images, setImages] = useState([]);

  const [dp1Value, setDp1Value] = useState({
    emoji: "nl",
    code: "+31",
  });

  // for meta
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");

  const addRate = () => {
    const rateObj = {
      id: rates.length + 1,
      hours: rate.hours,
      amount: rate.amount,
    };
    setRates([...rates, rateObj]);
    setRate({ hours: "", amount: "" });
  };

  const handleRateChange = (e, id, type) => {
    const updatedRates = rates.map((rate) => {
      if (rate.id === id) {
        return {
          ...rate,
          [type]: e.target.value,
        };
      }
      return rate;
    });

    setRates(updatedRates);
  };

  const removeElement = (id) => {
    const modifiedRates = rates.filter((rate) => rate.id !== id);
    setRates(modifiedRates);
  };

  const selectMainImage = (e) => {
    setMainImage(e.target.files[0]);
    mainImageRef.current.value = "";
  };

  const selectImages = (e) => {
    const imgArr = Array.from(e.target.files);
    const stateImgArrLength = images.length;

    if (stateImgArrLength + imgArr.length > 50 || imgArr.length > 50) {
      alert("Only 50 images are allowed");
      fileInputRef.current.value = "";
      return;
    }

    setImages([...images, ...imgArr]);

    fileInputRef.current.value = "";
  };

  const addAd = async () => {
    try {
      const formData = new FormData();

      const imagesForFormData = [];

      for (let i = 0; i < images.length; i++) {
        imagesForFormData.push(images[i]);
      }

      imagesForFormData.unshift(mainImage);

      for (let i = 0; i < imagesForFormData.length; i++) {
        formData.append("images", imagesForFormData[i]);
      }

      formData.append("name", name);
      formData.append("country", country);
      formData.append("description", description);
      formData.append("metaDescription", metaDescription);
      formData.append("metaTitle", metaTitle);
      formData.append("metaKeywords", metaKeywords);
      formData.append("ethnicity", ethnicity);
      formData.append("nationality", nationality);
      formData.append("sex", sex);
      formData.append("age", age);
      formData.append("isPornStar", isPornStar);
      formData.append("availability", availability);
      formData.append("height", height);
      formData.append("website", website);
      formData.append("phoneNumber", `${dp1Value.code} ${phoneNumber}`);
      formData.append("isOnWhatsApp", isOnWhatsApp);
      formData.append("isOnTelegram", isOnTelegram);
      selectedServices.forEach((service) => {
        formData.append("services[]", service);
      });
      selectedLanguages.forEach((language) => {
        formData.append("languages[]", language);
      });
      rates.length > 0 &&
        rates.forEach((rate) => {
          const rateWithoutId = { hours: rate.hours, amount: rate.amount };
          formData.append("rates[]", JSON.stringify(rateWithoutId));
        });

      await protectedMediaHttp.post("/admin/ad", formData);
      alert("Advertisement added successfully!");
      window.location.reload();
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  const handleAddService = (item) => {
    const existingService = selectedServices.find((service) => service === item);

    if (existingService) {
      const newServices = selectedServices.filter((service) => service !== item);
      setSelectedServices(newServices);
    } else {
      setSelectedServices([...selectedServices, item]);
    }
  };

  return (
    <>
      {/* <div className="dashboard-card-container">
        <h1 className="add-escort-page-title">Create new advertisement</h1>
      </div> */}

      <main>
        <div className="main-content">
          <div className="admin-top-bar-wrapper">
            <div className="admin-top-bar-container with-back">
              <button onClick={() => navigate(-1)}>
                <LeftSvg />
              </button>
              <h3>Create new advertisement</h3>
            </div>
          </div>
          <div className="escort-card-wrapper">
            <div className="form-container admin-add-form">
              <form onSubmit={addAd}>
                <div className="right">
                  <div className="row column-2">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        placeholder=" "
                        onChange={(e) => setName(e.target.value)}
                        id="name"
                        value={name}
                      />
                      <label htmlFor="name" className="placeHolderLabel">
                        Model Name
                      </label>
                    </div>
                    <Dropdown
                      data={countryArray}
                      handler={setCountry}
                      value={country}
                      placeholder={"Select country"}
                    />
                  </div>

                  <div className="row column-2">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        placeholder=" "
                        onChange={(e) => setHeight(e.target.value)}
                        id="height"
                        value={height}
                      />
                      <label htmlFor="name" className="placeHolderLabel">
                        Height
                      </label>
                    </div>

                    <div className="input-wrapper">
                      <input
                        type="text"
                        placeholder=" "
                        onChange={(e) => setMetaTitle(e.target.value)}
                        id="height"
                        value={metaTitle}
                      />
                      <label htmlFor="name" className="placeHolderLabel">
                        Meta title
                      </label>
                    </div>
                  </div>

                  <div className="row column-1">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        placeholder=" "
                        onChange={(e) => setMetaKeywords(e.target.value)}
                        id="height"
                        value={metaKeywords}
                      />
                      <label htmlFor="name" className="placeHolderLabel">
                        Meta Keywords
                      </label>
                    </div>
                  </div>

                  <div className="input-wrapper with-textarea">
                    <textarea
                      placeholder=" "
                      name=""
                      id="description"
                      cols="30"
                      rows="5"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                    />
                    <label htmlFor="name" className="placeHolderLabel">
                      description
                    </label>
                  </div>

                  <div className="input-wrapper with-textarea">
                    <textarea
                      placeholder=" "
                      name=""
                      id="metadescription"
                      cols="30"
                      rows="5"
                      onChange={(e) => setMetaDescription(e.target.value)}
                      value={metaDescription}
                    />
                    <label htmlFor="name" className="placeHolderLabel">
                      Metadescription
                    </label>
                  </div>

                  <div className="row rates phone">
                    <span>Rates</span>

                    <div>
                      <div>
                        <div className="rate-row main-rate-row">
                          <div className="input-wrapper hour">
                            <span>Hour*</span>
                            <input
                              onChange={(e) => setRate({ ...rate, hours: e.target.value })}
                              type="text"
                              placeholder="1"
                              maxLength={3}
                              value={rate.hours}
                            />
                          </div>

                          <div className="input-wrapper price">
                            <span>AED*</span>
                            <input
                              onChange={(e) => setRate({ ...rate, amount: e.target.value })}
                              type="text"
                              placeholder="500"
                              maxLength={5}
                              value={rate.amount}
                            />
                          </div>

                          <button onClick={addRate} type="button">
                            <AddImageSvg />
                          </button>
                        </div>
                        {rates.map((rate) => (
                          <div className="rate-row" key={rate.id}>
                            <div className="input-wrapper hour">
                              <span>Hour*</span>
                              <input
                                onChange={(e) => handleRateChange(e, rate.id, "hours")}
                                type="text"
                                placeholder="1"
                                maxLength={3}
                                value={rate.hours}
                              />
                            </div>

                            <div className="input-wrapper price">
                              <span>AED*</span>
                              <input
                                onChange={(e) => handleRateChange(e, rate.id, "amount")}
                                type="text"
                                placeholder="500"
                                maxLength={5}
                                value={rate.amount}
                              />
                            </div>

                            <button onClick={() => removeElement(rate.id)} type="button">
                              <RemoveSvg />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="row phone">
                    <span>Phone number</span>

                    <div>
                      <div className="country-code">
                        <CountryCode data={countryCodes} handler={setDp1Value} value={dp1Value} />
                      </div>

                      <div className="input-wrapper phone-number">
                        <input
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          type="text"
                          placeholder="123456789"
                          value={phoneNumber}
                        />
                      </div>

                      <div className="input-wrapper check-box">
                        <label>
                          <WhatsAppSvg />
                          <input
                            type="checkbox"
                            name="activity"
                            value="whatsapp"
                            onChange={() => setIsOnWhatsApp(!isOnWhatsApp)}
                          />
                        </label>
                      </div>

                      <div className="input-wrapper check-box">
                        <label>
                          <TelegramSvg />
                          <input
                            type="checkbox"
                            name="activity"
                            value="telegram"
                            onChange={() => setIsOnTelegram(!isOnTelegram)}
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row column-4">
                    <Dropdown
                      data={ethnicitiesArr}
                      handler={setEthnicity}
                      value={ethnicity}
                      placeholder={"Ethnicity"}
                    />

                    <Dropdown
                      data={nationalitiesArr}
                      handler={setNationality}
                      value={nationality}
                      placeholder={"Nationality"}
                    />
                    <Dropdown
                      data={availabilityArr}
                      handler={setAvailability}
                      value={availability}
                      placeholder={"Availability"}
                    />
                    <Dropdown
                      data={sexualOrientationsArr}
                      handler={setSex}
                      value={sex}
                      placeholder={"Sex"}
                    />

                    <Dropdown data={ageArr} handler={setAge} value={age} placeholder={"Age"} />

                    <DropdownWithCheckbox
                      data={languagesArr}
                      setSelectedArrayHandler={setSelectedLanguages}
                      selectedArray={selectedLanguages}
                      placeholder="Languages"
                    />
                    {/* <DropdownWithCheckbox
                      data={servicesArray}
                      setSelectedArrayHandler={setSelectedServices}
                      selectedArray={selectedServices}
                      placeholder="Services"
                      Customclass="right"
                    /> */}
                  </div>

                  <div className="input-wrapper services-form">
                    <p>choose service tags for advert service</p>
                    <ul className="services-array-container">
                      {servicesArray.map((service) => (
                        <li key={service}>
                          <div className="input-wrapper check-box">
                            <label>
                              <input type="checkbox" onClick={() => handleAddService(service)} />
                              {service}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="row column-2 single-responsive">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        placeholder=" "
                        onChange={(e) => setWebsite(e.target.value)}
                        id="website"
                        value={website}
                      />
                      <label htmlFor="name" className="placeHolderLabel">
                        Website
                      </label>
                    </div>
                    <div className="input-wrapper check-box pornstar-checkbox">
                      <label>
                        Are you a porn star ?
                        <input
                          onChange={() => setIsPornStar(!isPornStar)}
                          type="checkbox"
                          checked={isPornStar}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="left">
                  <div className="main-image input-wrapper image">
                    {mainImage === null ? (
                      <div className="image-input">
                        <label htmlFor="mainImage">
                          <i className="svg-wrapper">
                            <AddImageSvg />
                          </i>
                          Add main image
                        </label>
                        <input
                          id="mainImage"
                          onChange={selectMainImage}
                          type="file"
                          multiple="multiple"
                          accept=".png, .jpg, .jpeg"
                          size="12582912"
                          ref={mainImageRef}
                        />
                      </div>
                    ) : (
                      <div className="image container">
                        <img src={URL.createObjectURL(mainImage)} alt={mainImage.name} />

                        <button type="button" onClick={() => setMainImage(null)}>
                          <i className="svg-wrapper">
                            <RemoveSvg />
                          </i>
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="image-gallery">
                    <span>Image gallery</span>

                    {images.map((image, index) => (
                      <div className="input-wrapper image container" key={index}>
                        <img src={URL.createObjectURL(image)} alt={image.name} />

                        <button
                          type="button"
                          onClick={() => setImages(images.filter((img) => img !== image))}
                        >
                          <i className="svg-wrapper">
                            <RemoveSvg />
                          </i>
                        </button>
                      </div>
                    ))}

                    {images.length !== 10 && (
                      <div className="input-wrapper image">
                        <label htmlFor="profileImage">
                          <i className="svg-wrapper">
                            <AddImageSvg />
                          </i>
                        </label>
                        <input
                          id="profileImage"
                          onChange={selectImages}
                          type="file"
                          multiple="multiple"
                          accept=".png, .jpg, .jpeg"
                          size="12582912"
                          ref={fileInputRef}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <button onClick={addAd} type="button" className="input-wrapper btn">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddAdvertisement;
