import React from "react";

import { LogoImage, AuthBg1, AuthBg2 } from "../assets/images";
import { http } from "../helpers/http.helper";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const navigate = useNavigate();
  const [displayName, setDisplayName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [accountType, setAccountType] = React.useState("3");
  const [password, setPassword] = React.useState("");

  const registerUser = async (e) => {
    e.preventDefault();
    try {
      const res = await http.post("/auth/register", {
        displayName,
        email,
        password,
        role: accountType,
      });
      localStorage.setItem("uu_session", JSON.stringify(res.data.token));
      window.location.reload();
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  return (
    <div className="auth-wrapper signup">
      <img src={AuthBg1} className="background-image" alt="" />
      <img src={AuthBg2} className="background-wave" alt="" />

      <img src={LogoImage} alt="" srcset="" className="logo" />

      <div className="form-wrapper">
        <h2>Create an account</h2>

        <form onSubmit={registerUser} action="">
          <div className="row">
            <div className="title">
              You are an ? <div></div>
            </div>

            <div className="radiobtn">
              <input
                type="radio"
                id="individual"
                name="individual"
                value="3"
                checked
                onChange={(e) => setAccountType(e.target.value)}
              />
              <label htmlFor="individual">Individual</label>
              <input
                type="radio"
                id="agancy"
                name="individual"
                value="2"
                onChange={(e) => setAccountType(e.target.value)}
              />
              <label htmlFor="agancy">Agency</label>
            </div>
          </div>

          <div className="row">
            <div className="title">
              Basic details <div></div>
            </div>

            <div className="two-column">
              <input
                onChange={(e) => setDisplayName(e.target.value)}
                type="text"
                placeholder="Display name"
                name=""
                id=""
              />

              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Email"
                name=""
                id=""
              />
            </div>
          </div>

          <div className="row">
            <div className="title">
              Password <div></div>
            </div>

            <div className="two-column">
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Password"
                name=""
                id=""
              />
            </div>
          </div>

          <button>Sign up</button>

          <p className="link" onClick={() => navigate("/auth/login")}>
            Already have an account ?
          </p>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
