import React, { useState } from "react";
import { ReactComponent as HamburgerSvg } from "../../assets/svg/hamburger.svg";
import { ReactComponent as LogoutSvg } from "../../assets/svg/logout.svg";

import { LogoImage } from "../../assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import { protectedHttp } from "../../helpers/http.helper";

const AgencyTopbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);

  const logoutClient = async () => {
    if (window.confirm("Are you sure to logout?")) {
      protectedHttp
        .post("/auth/signout")
        .then(() => {
          localStorage.removeItem("uu_session");
          navigate("/");
        })
        .catch((err) => console.log(err.response.data.msg));
    }
  };

  return (
    <div className="nav-bar-wrapper">
      <div className="nav-bar-container">
        <img
          src={LogoImage}
          alt="Logo EscortHub"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />

        <button className="profile-toggle menu-toggle" onClick={() => setOpenMenu(!openMenu)}>
          <i className="svg-wrapper">
            <HamburgerSvg />
          </i>
        </button>

        <div className="auth-wrapper menu-items" style={{ display: openMenu ? "grid" : "none" }}>
          <span className="glasspane" onClick={() => setOpenMenu(!openMenu)}></span>
          <div>
            <nav className="login-wrapper navigation">
              <li
                onClick={() => {
                  navigate("/agency/dashboard");
                  setOpenMenu(!openMenu);
                }}
                className={location.pathname === "/agency/dashboard" ? "active" : ""}
              >
                Dashboard
              </li>
              <li
                onClick={() => {
                  navigate("/agency/my-ads");
                  setOpenMenu(!openMenu);
                }}
                className={location.pathname === "/agency/my-ads" ? "active" : ""}
              >
                My Ads
              </li>
              <li
                onClick={() => {
                  navigate("/agency/my-profile");
                  setOpenMenu(!openMenu);
                }}
                className={location.pathname === "/agency/my-profile" ? "active" : ""}
              >
                My Profile
              </li>
            </nav>
          </div>
        </div>

        <button className="" onClick={logoutClient}>
          <i className="svg-wrapper">
            <LogoutSvg />
          </i>
        </button>
      </div>
    </div>
  );
};

export default AgencyTopbar;
