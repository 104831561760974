import { create } from "zustand";

const appConfigStore = create((set) => ({
  isLoading: false,
  setIsLoading: (state) => {
    set({ isLoading: state });
  },
}));

export default appConfigStore;
