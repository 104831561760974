import React from "react";
import { DropdownWithCheckbox, FilterWrapper } from "../components";
import EscortCard from "../components/cards/EscortCard";
import Dropdown from "../components/elements/Dropdown";

import { ReactComponent as CloseBtnSvg } from "../assets/svg/close.svg";
import { ReactComponent as RightArrowSvg } from "../assets/svg/right.svg";

import { ageArray, servicesArray, nationalities } from "../utils/data";
import { http } from "../helpers/http.helper";
import CommonTopBar from "../components/layouts/CommonTopBar";
import { LogoImage } from "../assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import Navigation from "../components/layouts/Navigation";
import FooterSection from "../components/layouts/FooterSection";
import { Helmet } from "react-helmet";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [ads, setAds] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const maxPageButtons = 5;
  const halfMaxButtons = Math.floor(maxPageButtons / 2);
  const startPage = Math.max(1, page - halfMaxButtons);
  const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);
  const [warningShowed, setWarningShowed] = React.useState(false);
  /*filter*/
  const [selectedAge, setSelectedAge] = React.useState("");
  const [selectedServices, setSelectedServices] = React.useState([]);
  const [selectedNationality, setSelectedNationality] = React.useState("");
  /*end filter*/
  const [openFilters, setOpenFilters] = React.useState(false);

  const [links, setLinks] = React.useState([]);
  const [mobileLinks, setMobileLinks] = React.useState([]);
  const [selectedLink, setSelectedLink] = React.useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAds = async () => {
    try {
      const { data } = await http.get(
        `/pub/ads?page=${page}&limit=${12}&age=${selectedAge}&services=${selectedServices.join(
          ","
        )}&nationality=${selectedNationality}&country=${selectedLink.city}`
      );
      setAds(data.ads);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    const getClientIp = async () => {
      try {
        await http.get("/pub/set-today-view");
      } catch (error) {
        console.log(error);
      }
    };
    getClientIp();

    if (!localStorage.getItem("warningShowed")) {
      setWarningShowed(false);
    } else {
      setWarningShowed(true);
    }
  }, []);

  const getLinks = async () => {
    try {
      const { data } = await http.get("/pub/links");
      setLinks(data.links);

      const arr = data.links.map((link) => {
        return link.city;
      });
      setMobileLinks(arr);

      if (location.pathname === "/") {
        data.links.length > 0 &&
          data.links.map((link) => {
            if (link.city === "Dubai") {
              localStorage.setItem("selectedLink", link.text);
              return navigate(`/${link.text}`);
            }
            return null;
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePathName = () => {
    if (location.pathname !== "/") {
      const path = location.pathname.split("/")[1];

      if (links.length > 0) {
        const isLinkExist = links.find((link) => link.text === path);
        if (isLinkExist) {
          setSelectedLink(isLinkExist);
        }
      }
    }
  };

  React.useEffect(() => {
    handlePathName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, links]);

  const handleClickLink = (link) => {
    setSelectedLink(link);
    localStorage.setItem("selectedLink", link.text);
    navigate(`/${link.text}`);
    setPage(1);
  };

  const handleClickMobileLink = (city) => {
    const link = links.find((link) => link.city === city);
    setSelectedLink(link);
    localStorage.setItem("selectedLink", link.text);
    navigate(`/${link.text}`);
    setPage(1);
  };

  React.useEffect(() => {
    if (selectedLink) {
      getAds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLink, selectedAge, selectedServices, selectedNationality, page]);

  return (
    <>
      {selectedLink && (
        <Helmet>
          <title>{selectedLink.metaTitle}</title>
          <meta name="title" content={selectedLink.metaTitle} />
          <meta name="description" content={selectedLink.metaDescription} />
          <meta name="keywords" content={selectedLink.metaKeywords} />
          <meta name="robots" content="index, follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
          <meta name="revisit-after" content="7 days" />

          <meta
            property="og:type"
            content={`"Luxury Linkup - VIP Escort Service in ${selectedLink.city}, UAE | Dubai Escorts List"`}
          />
          <meta property="og:url" content="https://luxurylinkup.com/dubai" />
          <meta property="og:title" content={selectedLink.metaTitle} />
          <meta property="og:description" content={selectedLink.metaDescription} />
          <meta property="og:image" content="" />

          <meta property="twitter:card" content={selectedLink.metaTitle} />
          <meta property="twitter:url" content="https://luxurylinkup.com/" />
          <meta property="twitter:title" content={selectedLink.metaTitle} />
          <meta property="twitter:description" content={selectedLink.metaDescription} />
          <meta property="twitter:image" content="" />
        </Helmet>
      )}

      {/* {!warningShowed && (
        <div className="warning-message">
          <div className="content">
            <img src={LogoImage} alt="" />
            <p>
              luxurylinkup.com, including all webpages, links and images , displays sexually
              explicit material. Only consenting adults are authorized beyond this page. If you are
              a minor (under the age of 18 years or 21 years where 18 isn't the legal age of
              majority), if sexually explicit material offends you or if it's illegal to view such
              material in your community, you MUST leave this site by clicking "LEAVE" below. By
              clicking "ENTER", you agree to the statement at the bottom of this page.
            </p>
            <p>
              Luxury Linkup has a zero tolerance policy for child pornography or minors advertising
              or utilizing our site. I agree to report any illegal services or activities which
              violate Terms of Use. I also agree to report suspected exploitation of minors and/or
              human trafficking to the appropriate authorities.
            </p>
            <h1>BY ENTERING luxurylinkup.com YOU AGREE THAT:</h1>
            <ul>
              <li>
                you are an adult in your community and are at least 18 years old (21 in some
                countries)
              </li>
              <li>you agree with terms and conditions</li>

              <li>
                you agree that sexually explicit material is not deemed to be obscene or illegal in
                your community
              </li>

              <li>you accept full responsibility for your own actions</li>

              <li>you agree to the use of cookies</li>
            </ul>
            <h1>YOU HAVE READ THIS AGREEMENT, UNDERSTOOD IT AND AGREE TO BE BOUND BY IT.</h1>
            <button
              onClick={() => {
                localStorage.setItem("warningShowed", true);
                setWarningShowed(true);
              }}
            >
              Enter
            </button>
            <button
              onClick={() => {
                window.location.href = "https://www.google.com";
              }}
            >
              Leave
            </button>
          </div>
        </div>
      )} */}
      {/* {selectedLink && <CommonTopBar selectedCountry={selectedLink.city} />} */}
      {selectedLink && <CommonTopBar selectedCountry={selectedLink.metaTitle} />}
      <main>
        {/* sidebar */}
        <div className="side-bar"></div>
        {/* sidebar */}

        <div className="main-content">
          <div className="main-content-title-wrapper">
            <div className="main-content-title-container">
              {/* {selectedLink && <h1 className="selected-country-name">{selectedLink.city}</h1>} */}
              {selectedLink && <h1 className="selected-country-name">{selectedLink.metaTitle}</h1>}
              <Navigation />
              <button onClick={() => setOpenFilters(true)}>Filter</button>
            </div>
          </div>
          <FilterWrapper state={openFilters}>
            <h1>Filter</h1>
            <button onClick={() => setOpenFilters(false)}>
              <CloseBtnSvg />
            </button>

            {mobileLinks.length > 0 && (
              <Dropdown
                data={mobileLinks}
                value={selectedLink?.city}
                handler={handleClickMobileLink}
                placeholder="City"
                className={"country-picker-dropdown"}
              />
            )}
            <Dropdown
              data={nationalities}
              value={selectedNationality}
              handler={setSelectedNationality}
              placeholder="Nationality"
            />
            <Dropdown
              data={ageArray}
              value={selectedAge}
              handler={setSelectedAge}
              placeholder="Select age"
            />
            <DropdownWithCheckbox
              data={servicesArray}
              setSelectedArrayHandler={setSelectedServices}
              selectedArray={selectedServices}
              placeholder="Services"
            />
          </FilterWrapper>
          <div className="escort-card-wrapper with-countries">
            <div>
              <div className="countries">
                <ul>
                  {links.map((link) => (
                    <li
                      onClick={() => handleClickLink(link)}
                      className={selectedLink?.text === link?.text ? "selected" : ""}
                      key={link._id}
                    >
                      {link.city} <RightArrowSvg />
                    </li>
                  ))}
                </ul>
              </div>

              <div className="escort-card-container">
                {ads.map((ad) => (
                  <EscortCard key={ad._id} ad={ad} />
                ))}

                {/* pagination */}
                {totalPages > 1 && (
                  <div className="pagination home">
                    {startPage > 1 && <button onClick={() => setPage(startPage - 1)}>Prev</button>}
                    {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                      <button
                        key={startPage + index}
                        onClick={() => {
                          setPage(startPage + index);

                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        className={startPage + index === page ? "active" : ""}
                      >
                        {startPage + index}
                      </button>
                    ))}
                    {endPage < totalPages && (
                      <button onClick={() => setPage(endPage + 1)}>Next</button>
                    )}
                  </div>
                )}
                {/* pagination */}
              </div>
            </div>
          </div>
          <FooterSection selectedLink={selectedLink} links={links} />
        </div>
      </main>
      {/* <Footer /> */}
    </>
  );
};

export default Home;
