import React, { useState } from "react";
import { ReactComponent as LeftSvg } from "../../assets/svg/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import { protectedHttp } from "../../helpers/http.helper";

const AddAgency = () => {
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const createAgency = (e) => {
    e.preventDefault();

    protectedHttp
      .post("/admin/agency", {
        displayName,
        email,
        password,
      })
      .then(() => {
        navigate("/admin/all-agencies");
      })
      .catch((err) => console.log(err.response.data.msg));
  };

  return (
    <>
      {/* <div className="dashboard-card-container">
        <h1 className="add-escort-page-title">Create new advertisement</h1>
      </div> */}

      <main>
        <div className="main-content">
          <div className="admin-top-bar-wrapper">
            <div className="admin-top-bar-container with-back">
              <button onClick={() => navigate(-1)}>
                <LeftSvg />
              </button>
              <h3>Create new Agency</h3>
            </div>
          </div>
          <div className="escort-card-wrapper">
            <div className="form-container admin-add-form">
              <form onSubmit={createAgency}>
                <div className="right">
                  <div className="row column-2">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        placeholder="Display Name"
                        onChange={(e) => setDisplayName(e.target.value)}
                        id="name"
                        value={displayName}
                      />
                    </div>
                    <div className="input-wrapper">
                      <input
                        type="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        id="name"
                        value={email}
                      />
                    </div>
                  </div>
                  <div className="row column-2">
                    <div className="input-wrapper">
                      <input
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        id="name"
                        value={password}
                      />
                    </div>
                  </div>
                </div>

                <button type="submit" className="input-wrapper btn">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddAgency;
