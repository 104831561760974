import React from "react";
import { BASE_URL } from "../../helpers/http.helper";
import { Dummy2 } from "../../assets/images";
import { useNavigate } from "react-router-dom";

const AgencyViewCard = ({ _id, banner, displayName, adsCount }) => {
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(`/agency-ads/${_id}`)} className="escort-card user agency-card">
      {banner ? (
        <img src={`${BASE_URL}/agencies/${banner}`} alt="" width="100px" height="100px" />
      ) : (
        <img src={Dummy2} alt="" width="100px" height="100px" />
      )}
      <div>
        <h3>{displayName}</h3>
        <span>{adsCount}</span>
      </div>
    </div>
  );
};

export default AgencyViewCard;
