import { Home, SingleEscort } from "./pages";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Auth from "./pages/Auth";
import Admin from "./pages/admin/Admin";
import Top50 from "./pages/Top50";
import PornStars from "./pages/PornStars";
import Agency from "./pages/agency/Agency";
import Escort from "./pages/escort/Escort";
import Agencies from "./pages/Agencies";
import About from "./pages/About";
import Terms from "./pages/Terms";
import Policy from "./pages/Policy";
import AgencyAds from "./pages/AgencyAds";
import NotFound from "./pages/NotFound";
import Partners from "./pages/Partners";

const App = () => {
  return (
    <div className="wrapper">
      <Router>
        <Routes>
          <Route path="/:country?" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms-conditions" element={<Terms />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/top-50" element={<Top50 />} />
          <Route path="/agency-ads/:id" element={<AgencyAds />} />
          <Route path="/porn-stars" element={<PornStars />} />
          <Route path="/agencies" element={<Agencies />} />
          <Route path="/escort/:name" element={<SingleEscort />} />
          <Route path="/auth/*" element={<Auth />} />
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/agency/*" element={<Agency />} />
          <Route path="/escort/*" element={<Escort />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
