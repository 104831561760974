import React from "react";
import CommonTopBar from "../components/layouts/CommonTopBar";
import { LogoImage } from "../assets/images";
import { Helmet } from "react-helmet";
import Navigation from "../components/layouts/Navigation";
import FooterSection from "../components/layouts/FooterSection";
import { protectedHttp } from "../helpers/http.helper";

const Partners = () => {
  const [partners, setPartners] = React.useState([]);

  const getPartners = async () => {
    try {
      const { data } = await protectedHttp.get("/pub/get-partners");
      setPartners(data.partners);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> {`Luxury Linkup - Partners`} </title>
        <meta name="title" content={`Luxury Linkup - Partners`} />

        {/* <!-- Open Graph Meta Tags --> */}
        <meta property="og:title" content={`Luxury Linkup - Partners`} />

        <meta property="og:image" content={LogoImage} />
        <meta property="og:url" content={`https://luxurylinkup.com/partners`} />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Luxury Linkup - Partners`} />

        <meta name="twitter:image" content={LogoImage} />
        <meta name="twitter:url" content={`https://luxurylinkup.com/partners`} />

        <link rel="canonical" href={`https://luxurylinkup.com/partners`} />
      </Helmet>
      <CommonTopBar selectedCountry="Partners" />
      <main>
        <div className="main-content">
          <div className="main-content-title-wrapper without-filters">
            <div className="main-content-title-container">
              <h1 className="selected-country-name">Partners</h1>
              <Navigation />
            </div>
          </div>

          <div className="our-banner">
            <div>
              <img src={LogoImage} alt="" srcset="" />
              <div className="input-wrapper">
                <input
                  type="text"
                  placeholder=""
                  id="name"
                  disabled
                  value="https://luxurylinkup.com/logo.png"
                />
                <label htmlFor="name" className="placeHolderLabel">
                  Image Link
                </label>
              </div>
            </div>
          </div>

          <div className="escort-card-wrapper">
            <h2 className="partner-title">Our Partners</h2>
            <div className="escort-card-container partners">
              {partners.map((partner) => (
                <div className="partner-card">
                  <a href={partner.partnerLink} target="_blank" rel="dofollow">
                    <img src={partner.imageLink} alt="" />
                  </a>
                </div>
              ))}
            </div>
          </div>

          <FooterSection />
        </div>
      </main>
    </>
  );
};

export default Partners;
