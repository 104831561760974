import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const condition =
    location.pathname !== "/top-50" &&
    location.pathname !== "/porn-stars" &&
    location.pathname !== "/agencies" &&
    location.pathname !== "/privacy-policy" &&
    location.pathname !== "/partners" &&
    location.pathname !== "/terms-conditions";

  return (
    <nav className="navigation">
      <li
        onClick={() => navigate(`/${localStorage.getItem("selectedLink")}`)}
        className={location.pathname.startsWith("/") && condition && "active"}
      >
        Escorts
      </li>
      <li
        onClick={() => {
          navigate("/top-50");
        }}
        className={location.pathname === "/top-50" && "active"}
      >
        Top 50 Escorts
      </li>
      <li
        onClick={() => {
          navigate("/porn-stars");
        }}
        className={location.pathname === "/porn-stars" && "active"}
      >
        Pornstar Escorts
      </li>
      <li
        onClick={() => {
          navigate("/agencies");
        }}
        className={location.pathname === "/agencies" && "active"}
      >
        Agencies
      </li>
    </nav>
  );
};

export default Navigation;
