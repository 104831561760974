import React from "react";

import { LogoImage, AuthBg1, AuthBg2 } from "../assets/images";
import { http } from "../helpers/http.helper";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const loginUser = async (e) => {
    e.preventDefault();
    try {
      const res = await http.post("/auth/login", { email, password });
      localStorage.setItem("uu_session", JSON.stringify(res.data.token));
      window.location.reload();
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  return (
    <div className="auth-wrapper">
      <img src={AuthBg1} className="background-image" alt="" />
      <img src={AuthBg2} className="background-wave" alt="" />

      <img src={LogoImage} alt="" srcset="" className="logo" />

      <div className="form-wrapper">
        <h2>Sign in</h2>

        <form onSubmit={loginUser} action="">
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Email"
            name=""
            id=""
          />
          <input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
            name=""
            id=""
          />
          <button>Sign in</button>

          <p className="link" onClick={() => navigate("/auth/signup")}>
            Create an account
          </p>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
