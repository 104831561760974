import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlusSvg } from "../../assets/svg/plus.svg";
import { protectedHttp } from "../../helpers/http.helper";

const DashboardNew = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = React.useState({
    adsCount: 0,
    todayViewsCount: 0,
    totalAgencyCount: 0,
    totalIndependentCount: 0,
  });

  const getDashboard = React.useCallback(async () => {
    try {
      const { data } = await protectedHttp.get("/admin/dashboard");
      const { adsCount, todayViewsCount, totalAgencyCount, totalIndependentCount } = data;
      setDashboardData({ adsCount, todayViewsCount, totalAgencyCount, totalIndependentCount });
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    getDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <main>
        <div className="main-content">
          <div className="admin-top-bar-wrapper">
            <div className="admin-top-bar-container">
              <h1>Dashboard</h1>

              {/* <button onClick={() => navigate("/admin/create")}>
                Create Ad <PlusSvg />
              </button> */}
            </div>
          </div>
          <div className="admin-dashboard-card-wrapper">
            <div className="admin-dashboard-card-container">
              <div className="admin-dashboard-card">
                <h1>Total Ads</h1>
                <p>{dashboardData.adsCount}</p>
              </div>

              <div className="admin-dashboard-card">
                <h1>Today Views</h1>
                <p>{dashboardData.todayViewsCount}</p>
              </div>

              <div className="admin-dashboard-card">
                <h1>Total Agencies</h1>
                <p>{dashboardData.totalAgencyCount}</p>
              </div>

              <div className="admin-dashboard-card">
                <h1>Total Independant Escorts</h1>
                <p>{dashboardData.totalIndependentCount}</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default DashboardNew;
