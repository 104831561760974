import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlusSvg } from "../../assets/svg/plus.svg";
import { protectedHttp } from "../../helpers/http.helper";

const EscortDashboard = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = React.useState({
    views: 0,
    adCount: 0,
  });

  const getDashboard = React.useCallback(async () => {
    try {
      const { data } = await protectedHttp.get("/escort/dashboard");
      const { views, adCount } = data;
      setDashboardData({ views, adCount });
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    getDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <main>
        <div className="main-content">
          <div className="admin-top-bar-wrapper">
            <div className="admin-top-bar-container">
              <h1>Dashboard</h1>

              {dashboardData.adCount === 0 && (
                <button onClick={() => navigate("/escort/create")}>
                  Create Ad <PlusSvg />
                </button>
              )}
            </div>
          </div>
          <div className="admin-dashboard-card-wrapper">
            <div className="admin-dashboard-card-container">
              <div className="admin-dashboard-card">
                <h1>Total Views</h1>
                <p>{dashboardData.views}</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EscortDashboard;
