import React from "react";
import { ReactComponent as EditSvg } from "../../assets/svg/edit.svg";
import { ReactComponent as TrashSvg } from "../../assets/svg/trash-2.svg";
import { useNavigate } from "react-router-dom";

const AgencyCard = ({ agency, deleteHandler }) => {
  const navigate = useNavigate();

  return (
    <div className="listing-table-row">
      <div className="td-name">{agency.displayName}</div>
      <div className="td-email">{agency.email}</div>
      <div className="td-actions">
        <div className="button-wrapper">
          {/* <button onClick={() => navigate(`/admin/update/${agency._id}`)}>
            Edit <EditSvg />
          </button> */}
          <button onClick={deleteHandler}>
            Delete <TrashSvg />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgencyCard;
