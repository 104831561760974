import React from "react";
import ReactSwitch from "react-switch";
import { ReactComponent as EditSvg } from "../../assets/svg/edit.svg";
import { ReactComponent as TrashSvg } from "../../assets/svg/trash-2.svg";
import { BASE_URL } from "../../helpers/http.helper";

const AdCard = ({ ad, handleStatus, updateHandler, deleteHandler }) => {
  return (
    <div className="listing-table-row">
      <div className="td-picture">
        <img src={`${BASE_URL}/img/${ad.mainImage}`} alt="" />
      </div>
      <div className="td-name">{ad.name}</div>
      <div className="td-status">
        <div className="switch">
          <ReactSwitch
            onChange={(status) => handleStatus(ad, status)}
            checked={ad.status}
            className="react-switch"
          />
        </div>
      </div>
      <div className="td-actions">
        <div className="button-wrapper">
          <button onClick={updateHandler}>
            Edit <EditSvg />
          </button>
          <button onClick={deleteHandler}>
            Delete <TrashSvg />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdCard;
