import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { getClientInstance } from "../../helpers/middleware.helper";
import appConfigStore from "../../store/appConfigStore";
import EscortDashboard from "./EscortDashboard";
import EscortTopbar from "../../components/layouts/EscortTopbar";
import EscortAddAdvertisement from "./EscortAddAdvertisement";
import EscortUpdateAdvertisement from "./EscortUpdateAdvertisement";
import MyAdvertisement from "./MyAdvertisement";
import { Helmet } from "react-helmet";
import { LogoImage } from "../../assets/images";

const Escort = () => {
  const { isLoading, setIsLoading } = appConfigStore();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const initializeInstance = async () => {
      setIsLoading(true);
      const instance = await getClientInstance();

      if (instance === 1) {
        navigate("/admin/dashboard");
      } else if (instance === 2) {
        navigate("/agency/dashboard");
      } else if (instance === 3) {
        if (location.pathname === "/escort" || location.pathname === "/escort/")
          navigate("/escort/dashboard");
      } else {
        navigate("/");
      }
      setIsLoading(false);
    };

    initializeInstance();
  }, [location.pathname, navigate, setIsLoading]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> {`Luxury Linkup - Dashboard`} </title>
        <meta name="title" content={`Luxury Linkup - Dashboard`} />

        {/* <!-- Open Graph Meta Tags --> */}
        <meta property="og:title" content={`Luxury Linkup - Dashboard`} />

        <meta property="og:image" content={LogoImage} />
        <meta property="og:url" content={`https://luxurylinkup.com`} />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Luxury Linkup - Dashboard`} />

        <meta name="twitter:image" content={LogoImage} />
        <meta name="twitter:url" content={`https://luxurylinkup.com`} />

        <link rel="canonical" href={`https://luxurylinkup.com`} />
      </Helmet>
      <EscortTopbar />
      {isLoading && <div className="overlay" />}

      <Routes>
        <Route path="dashboard" element={<EscortDashboard />} />
        <Route path="my-ad" element={<MyAdvertisement />} />
        <Route path="create" element={<EscortAddAdvertisement />} />
        <Route path="/update/:id" element={<EscortUpdateAdvertisement />} />
      </Routes>
    </>
  );
};

export default Escort;
