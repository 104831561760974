import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as DropDownSvg } from "../../assets/svg/dropdown.svg";

const CountryCode = ({ data, handler, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  // Attach a click event listener to the document to handle clicks outside of the dropdown
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="input-wrapper drop-down-wrappper" ref={dropdownRef}>
      <input type="text" hidden />
      <div className="drop-down-label" onClick={toggleDropdown}>
        <label>
          <img src={`https://flagcdn.com/24x18/${value.emoji}.png`} alt="" srcSet="" />
          <span>{value.code}</span>
        </label>
        <i className="svg-wrapper">
          <DropDownSvg />
        </i>
      </div>
      {isOpen && (
        <ul className="drop-down-container">
          {data.countryCodes.map((country, index) => (
            <li
              key={index}
              onClick={() => {
                handler(country);
                setIsOpen(false);
              }}
            >
              <img src={`https://flagcdn.com/24x18/${country.emoji}.png`} alt="" srcSet="" />{" "}
              {country.code}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CountryCode;
