import React from "react";
import { protectedHttp } from "../../helpers/http.helper";

import { ReactComponent as PlusSvg } from "../../assets/svg/plus.svg";
import { useNavigate } from "react-router-dom";
import AgencyCard from "../../components/cards/AgencyCard";

const AllIndependentEscorts = () => {
  const [escorts, setEscorts] = React.useState([]);

  const navigate = useNavigate();

  const getIndependantEscorts = React.useCallback(async () => {
    try {
      const { data } = await protectedHttp.get("/admin/independant/all");
      setEscorts(data.independantEscorts);
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    getIndependantEscorts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteEscort = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      protectedHttp
        .delete(`/admin/agency/${id}`)
        .then(() => {
          getIndependantEscorts();
        })
        .catch((err) => console.log(err.response.data.msg));
    }
  };

  return (
    <>
      <main>
        <div className="main-content">
          <div className="admin-top-bar-wrapper">
            <div className="admin-top-bar-container">
              <h1>All Independent Escorts</h1>

              <button onClick={() => navigate("/admin/add-independent")}>
                <PlusSvg />
                Create Independent Escort
              </button>
            </div>
          </div>
          <div className="listing-table-wrapper">
            <div className="listing-table">
              <div className="listing-table-row listing-table-header">
                <div className="td-name">Name</div>
                <div className="td-email">Email</div>
                <div className="td-actions">Actions</div>
              </div>

              {escorts.map((escort) => (
                <AgencyCard
                  key={escort._id}
                  agency={escort}
                  deleteHandler={() => deleteEscort(escort._id)}
                />
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AllIndependentEscorts;
