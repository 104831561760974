import { protectedHttp } from "./http.helper";

export const getClientInstance = async () => {
  if (localStorage.getItem("uu_session")) {
    try {
      const res = await protectedHttp.get("/auth/get-instance");
      return res.data.instance;
    } catch (error) {
      console.error("Error fetching client instance:", error);
      return null;
    }
  } else {
    return false;
  }
};
