import React, { useEffect, useState } from "react";
// import { ReactComponent as FiltersSvg } from "../../assets/svg/filters.svg";

const FilterBarWrapper = ({ children, state }) => {
  // const [showFilters, setShowFilters] = useState(false);
  const [screenSize, setScreenSize] = useState(false);
  const [display, setDisplay] = useState("flex");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 575) {
        setScreenSize(true);
      } else {
        setScreenSize(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (screenSize) {
      setDisplay("none");
    } else {
      setDisplay("flex");
    }

    if (state) {
      setDisplay("flex");
    }
  }, [display, screenSize, state]);

  return (
    <div className="filter-bar-wrapper" style={{ display }}>
      <div className="filters-container">
        {/* <button className="filter-pop-up-btn" onClick={() => setShowFilters(!showFilters)}>
          Filters <FiltersSvg />
        </button> */}

        {/* <div
          className={`filters-components-wrapper ${
            !showFilters && screenSize && "hide"
          }`}
        >
          {children}
        </div> */}

        <div className="filters-components-wrapper">{children}</div>
      </div>
    </div>
  );
};

export default FilterBarWrapper;
