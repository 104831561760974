export const availabilityArr = ["Outcall", "Incall", "Outcall + Incall"];

export const nationalitiesArr = [
  "Albanian",
  "American",
  "Argentinian",
  "Armenian",
  "Australian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladesh",
  "Barbados",
  "Belarussian",
  "Belgian",
  "Belizean",
  "Bolivian",
  "Brazilian",
  "British",
  "Bulgarian",
  "Cameroon",
  "Canadian",
  "Chilean",
  "Chinese",
  "Colombian",
  "Congolese",
  "Costa Rica",
  "Croatian",
  "Cuban",
  "Cyprus",
  "Czech",
  "Danish",
  "Dominican",
  "Dutch",
  "Ecuadorian",
  "Egyptian",
  "Emiratis",
  "English",
  "Estonian",
  "Ethiopian",
  "Filipino",
  "Finnish",
  "French",
  "Georgian",
  "German",
  "Ghanaian",
  "Greek",
  "Hondurasian",
  "Hungarian",
  "Indian",
  "Indonesian",
  "Iranian",
  "Irish",
  "Israeli",
  "Italian",
  "Ivorian",
  "Jamaican",
  "Japanese",
  "Kazakh",
  "Kenyan",
  "Korean",
  "Kuwaiti",
  "Lao",
  "Latvian",
  "Lebanese",
  "Lithuanian",
  "Luxembourger",
  "Malaysian",
  "Maltese",
  "Mexican",
  "Moldovan",
  "Mongolian",
  "Montenegrian",
  "Moroccan",
  "Mozambican",
  "Nepali",
  "New Zealander",
  "Nigerian",
  "Norwegian",
  "Omani",
  "Pakistan",
  "Panama",
  "Paraguayan",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Puerto Rican",
  "Romanian",
  "Russian",
  "Saudi",
  "Serbian",
  "Singaporean",
  "Slovak",
  "Slovenian",
  "South African",
  "Spanish",
  "Swedish",
  "Swiss",
  "Taiwanese",
  "Tanzanian",
  "Thai",
  "Trinidadian",
  "Tunisian",
  "Turkish",
  "Ugandan",
  "Ukrainian",
  "Uruguayan",
  "Venezuelan",
  "Vietnamese",
];

export const languagesArr = [
  "Afrikaans",
  "Albanian",
  "Arabic",
  "Armenian",
  "Azerbaijani",
  "Basque",
  "Belarusian",
  "Bengali",
  "Bihari",
  "Bosnian",
  "Breton",
  "Bulgarian",
  "Catalan",
  "Chinese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "Estonian",
  "English",
  "Filipino",
  "Finnish",
  "French",
  "Galician",
  "Georgian",
  "German",
  "Greek",
  "Guarani",
  "Gujarati",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Icelandic",
  "Indonesian",
  "Interlingua",
  "Irish",
  "Italian",
  "Japanese",
  "Javanese",
  "Kannada",
  "Korean",
  "Kurdish",
  "Kyrgyz",
  "Laothian",
  "Latin",
  "Latvian",
  "Lithuanian",
  "Luxembourgish",
  "Macedonian",
  "Malay",
  "Malayalam",
  "Maltese",
  "Marathi",
  "Mongolian",
  "Nepali",
  "Norwegian",
  "Occitan",
  "Oriya",
  "Persian",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Romanian",
  "Russian",
  "Scots Gaelic",
  "Serbian",
  "Serbo-Croatian",
  "Sesotho",
  "Sindhi",
  "Sinhalese",
  "Slovak",
  "Slovenian",
  "Somali",
  "Sundanese",
  "Swahili",
  "Swedish",
  "Tamil",
  "Telugu",
  "Thai",
  "Tigrinya",
  "Turkish",
  "Turkmen",
  "Uighur",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Welsh",
  "Yiddish",
];

export const sexualOrientationsArr = ["straight", "bisexual", "lesbian", "homosexual"];

export const ethnicitiesArr = [
  "Arabian",
  "Asian",
  "Ebony",
  "Caucasian",
  "Hispanic",
  "Indian",
  "Latin",
  "Mongolia",
  "Mixed race",
];

export const countryCodes = {
  countryCodes: [
    {
      emoji: "us",
      code: "+1",
    },
    {
      emoji: "ca",
      code: "+1",
    },
    {
      emoji: "jm",
      code: "+1876",
    },
    {
      emoji: "eg",
      code: "+20",
    },
    {
      emoji: "ma",
      code: "+212",
    },
    {
      emoji: "dz",
      code: "+213",
    },
    {
      emoji: "tn",
      code: "+216",
    },
    {
      emoji: "gm",
      code: "+220",
    },
    {
      emoji: "sn",
      code: "+221",
    },
    {
      emoji: "ml",
      code: "+223",
    },
    {
      emoji: "ci",
      code: "+225",
    },
    {
      emoji: "bf",
      code: "+226",
    },
    {
      emoji: "tg",
      code: "+228",
    },
    {
      emoji: "bj",
      code: "+229",
    },
    {
      emoji: "mu",
      code: "+230",
    },
    {
      emoji: "gh",
      code: "+233",
    },
    {
      emoji: "ng",
      code: "+234",
    },
    {
      emoji: "cm",
      code: "+237",
    },
    {
      emoji: "cv",
      code: "+238",
    },
    {
      emoji: "cg",
      code: "+242",
    },
    {
      emoji: "cd",
      code: "+243",
    },
    {
      emoji: "et",
      code: "+251",
    },
    {
      emoji: "ke",
      code: "+254",
    },
    {
      emoji: "tz",
      code: "+255",
    },
    {
      emoji: "ug",
      code: "+256",
    },
    {
      emoji: "mz",
      code: "+258",
    },
    {
      emoji: "zm",
      code: "+260",
    },
    {
      emoji: "mg",
      code: "+261",
    },
    {
      emoji: "za",
      code: "+27",
    },
    {
      emoji: "aw",
      code: "+297",
    },
    {
      emoji: "gr",
      code: "+30",
    },
    {
      emoji: "nl",
      code: "+31",
    },
    {
      emoji: "be",
      code: "+32",
    },
    {
      emoji: "fr",
      code: "+33",
    },
    {
      emoji: "es",
      code: "+34",
    },
    {
      emoji: "pt",
      code: "+351",
    },
    {
      emoji: "lu",
      code: "+352",
    },
    {
      emoji: "ie",
      code: "+353",
    },
    {
      emoji: "is",
      code: "+354",
    },
    {
      emoji: "al",
      code: "+355",
    },
    {
      emoji: "mt",
      code: "+356",
    },
    {
      emoji: "cy",
      code: "+357",
    },
    {
      emoji: "fi",
      code: "+358",
    },
    {
      emoji: "bg",
      code: "+359",
    },
    {
      emoji: "hu",
      code: "+36",
    },
    {
      emoji: "lt",
      code: "+370",
    },
    {
      emoji: "lv",
      code: "+371",
    },
    {
      emoji: "ee",
      code: "+372",
    },
    {
      emoji: "md",
      code: "+373",
    },
    {
      emoji: "am",
      code: "+374",
    },
    {
      emoji: "by",
      code: "+375",
    },
    {
      emoji: "ad",
      code: "+376",
    },
    {
      emoji: "mc",
      code: "+377",
    },
    {
      emoji: "ua",
      code: "+380",
    },
    {
      emoji: "rs",
      code: "+381",
    },
    {
      emoji: "me",
      code: "+382",
    },
    {
      emoji: "xk",
      code: "+383",
    },
    {
      emoji: "hr",
      code: "+385",
    },
    {
      emoji: "si",
      code: "+386",
    },
    {
      emoji: "ba",
      code: "+387",
    },
    {
      emoji: "mk",
      code: "+389",
    },
    {
      emoji: "it",
      code: "+39",
    },
    {
      emoji: "ro",
      code: "+40",
    },
    {
      emoji: "ch",
      code: "+41",
    },
    {
      emoji: "cz",
      code: "+420",
    },
    {
      emoji: "sk",
      code: "+421",
    },
    {
      emoji: "li",
      code: "+423",
    },
    {
      emoji: "at",
      code: "+43",
    },
    {
      emoji: "gb",
      code: "+44",
    },
    {
      emoji: "dk",
      code: "+45",
    },
    {
      emoji: "se",
      code: "+46",
    },
    {
      emoji: "no",
      code: "+47",
    },
    {
      emoji: "pl",
      code: "+48",
    },
    {
      emoji: "de",
      code: "+49",
    },
    {
      emoji: "bz",
      code: "+501",
    },
    {
      emoji: "gt",
      code: "+502",
    },
    {
      emoji: "ni",
      code: "+505",
    },
    {
      emoji: "cr",
      code: "+506",
    },
    {
      emoji: "pa",
      code: "+507",
    },
    {
      emoji: "pe",
      code: "+51",
    },
    {
      emoji: "mx",
      code: "+52",
    },
    {
      emoji: "cu",
      code: "+53",
    },
    {
      emoji: "ar",
      code: "+54",
    },
    {
      emoji: "br",
      code: "+55",
    },
    {
      emoji: "cl",
      code: "+56",
    },
    {
      emoji: "co",
      code: "+57",
    },
    {
      emoji: "ve",
      code: "+58",
    },
    {
      emoji: "gp",
      code: "+590",
    },
    {
      emoji: "ec",
      code: "+593",
    },
    {
      emoji: "py",
      code: "+595",
    },
    {
      emoji: "mq",
      code: "+596",
    },
    {
      emoji: "uy",
      code: "+598",
    },
    {
      emoji: "cw",
      code: "+599",
    },
    {
      emoji: "my",
      code: "+60",
    },
    {
      emoji: "au",
      code: "+61",
    },
    {
      emoji: "id",
      code: "+62",
    },
    {
      emoji: "ph",
      code: "+63",
    },
    {
      emoji: "nz",
      code: "+64",
    },
    {
      emoji: "sg",
      code: "+65",
    },
    {
      emoji: "th",
      code: "+66",
    },
    {
      emoji: "pf",
      code: "+689",
    },
    {
      emoji: "kz",
      code: "+7",
    },
    {
      emoji: "ru",
      code: "+7",
    },
    {
      emoji: "jp",
      code: "+81",
    },
    {
      emoji: "kr",
      code: "+82",
    },
    {
      emoji: "vn",
      code: "+84",
    },
    {
      emoji: "hk",
      code: "+852",
    },
    {
      emoji: "mo",
      code: "+853",
    },
    {
      emoji: "kh",
      code: "+855",
    },
    {
      emoji: "la",
      code: "+856",
    },
    {
      emoji: "cn",
      code: "+86",
    },
    {
      emoji: "bd",
      code: "+880",
    },
    {
      emoji: "tw",
      code: "+886",
    },
    {
      emoji: "tr",
      code: "+90",
    },
    {
      emoji: "in",
      code: "+91",
    },
    {
      emoji: "pk",
      code: "+92",
    },
    {
      emoji: "af",
      code: "+93",
    },
    {
      emoji: "lk",
      code: "+94",
    },
    {
      emoji: "mm",
      code: "+95",
    },
    {
      emoji: "lb",
      code: "+961",
    },
    {
      emoji: "jo",
      code: "+962",
    },
    {
      emoji: "sy",
      code: "+963",
    },
    {
      emoji: "iq",
      code: "+964",
    },
    {
      emoji: "kw",
      code: "+965",
    },
    {
      emoji: "sa",
      code: "+966",
    },
    {
      emoji: "ye",
      code: "+967",
    },
    {
      emoji: "om",
      code: "+968",
    },
    {
      emoji: "ps",
      code: "+970",
    },
    {
      emoji: "ae",
      code: "+971",
    },
    {
      emoji: "il",
      code: "+972",
    },
    {
      emoji: "bh",
      code: "+973",
    },
    {
      emoji: "qa",
      code: "+974",
    },
    {
      emoji: "mn",
      code: "+976",
    },
    {
      emoji: "np",
      code: "+977",
    },
    {
      emoji: "ir",
      code: "+98",
    },
    {
      emoji: "az",
      code: "+994",
    },
    {
      emoji: "ge",
      code: "+995",
    },
    {
      emoji: "kg",
      code: "+996",
    },
    {
      emoji: "uz",
      code: "+998",
    },
  ],
};

export const citiesArr = [
  "Dubai",
  "Abu Dhabi",
  "Sharjah",
  "Al Ain",
  "Ajman",
  "Ras Al Khaimah",
  "Fujairah",
  "Umm Al Quwain",
  "Khor Fakkan",
  "Kalba",
  "Madinat Zayed",
  "Dibba Al-Fujairah",
  "Ruwais",
  "Ghayathi",
  "Dhaid",
  "Jebel Ali",
  "Liwa Oasis",
  "Hatta",
  "Ar-Rams",
  "Dibba Al-Hisn",
  "Al Jazirah Al Hamra",
];

export const ageArr = [
  18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65,
  66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80,
];
