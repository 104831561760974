import React from "react";
import { http } from "../helpers/http.helper";
import EscortCard from "../components/cards/EscortCard";
import CommonTopBar from "../components/layouts/CommonTopBar";
import { Helmet } from "react-helmet";
import { LogoImage } from "../assets/images";
import Navigation from "../components/layouts/Navigation";
import FooterSection from "../components/layouts/FooterSection";

const Top50 = () => {
  const [ads, setAds] = React.useState([]);

  const getAds = React.useCallback(async () => {
    try {
      const { data } = await http.get("/pub/top50-ads");
      setAds(data.ads);
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    getAds();
  }, [getAds]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> {`Luxury Linkup - Top 50`} </title>
        <meta name="title" content={`Luxury Linkup - Top 50`} />

        {/* <!-- Open Graph Meta Tags --> */}
        <meta property="og:title" content={`Luxury Linkup - Top 50`} />

        <meta property="og:image" content={LogoImage} />
        <meta property="og:url" content={`https://luxurylinkup.com/top-50`} />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Luxury Linkup - Top 50`} />

        <meta name="twitter:image" content={LogoImage} />
        <meta name="twitter:url" content={`https://luxurylinkup.com/top-50`} />

        <link rel="canonical" href={`https://luxurylinkup.com/top-50`} />
      </Helmet>
      <CommonTopBar selectedCountry="Top 50 Escorts" />
      <main>
        <div className="main-content">
          <div className="main-content-title-wrapper without-filters">
            <div className="main-content-title-container">
              <h1 className="selected-country-name">Top 50 Escorts</h1>
              <Navigation />
            </div>
          </div>
          {/* <h1 className="page-titles">Top 50 Escorts</h1> */}
          <div className="escort-card-wrapper">
            <div className="escort-card-container">
              {ads.map((ad) => (
                <EscortCard key={ad._id} ad={ad} />
              ))}
            </div>
          </div>
          <FooterSection />
        </div>
      </main>
    </>
  );
};

export default Top50;
