import React from "react";
import { http } from "../helpers/http.helper";
import EscortCard from "../components/cards/EscortCard";
import CommonTopBar from "../components/layouts/CommonTopBar";
import { Helmet } from "react-helmet";
import { LogoImage } from "../assets/images";
import Navigation from "../components/layouts/Navigation";
import FooterSection from "../components/layouts/FooterSection";
import { useParams } from "react-router-dom";

const AgencyAds = () => {
  const { id } = useParams();
  const [ads, setAds] = React.useState([]);

  const getAds = React.useCallback(async () => {
    try {
      const { data } = await http.get(`/pub/get-agency-ads/${id}`);
      setAds(data.ads);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  React.useEffect(() => {
    getAds();
  }, [getAds]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> {`Luxury Linkup - Agency Ads`} </title>
        <meta name="title" content={`Luxury Linkup - Agency Ads`} />

        {/* <!-- Open Graph Meta Tags --> */}
        <meta property="og:title" content={`Luxury Linkup - Agency Ads`} />

        <meta property="og:image" content={LogoImage} />
        <meta property="og:url" content={`https://luxurylinkup.com`} />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Luxury Linkup - Agency Ads`} />

        <meta name="twitter:image" content={LogoImage} />
        <meta name="twitter:url" content={`https://luxurylinkup.com`} />

        <link rel="canonical" href={`https://luxurylinkup.com`} />
      </Helmet>
      <CommonTopBar selectedCountry="Agency Ads" />
      <main>
        <div className="main-content">
          <div className="main-content-title-wrapper without-filters">
            <div className="main-content-title-container">
              <h1 className="selected-country-name">Agency Ads</h1>
              <Navigation />
            </div>
          </div>
          {/* <h1 className="page-titles">Top 50 Escorts</h1> */}
          <div className="escort-card-wrapper">
            <div className="escort-card-container">
              {ads.map((ad) => (
                <EscortCard key={ad._id} ad={ad} />
              ))}
            </div>
          </div>
          <FooterSection />
        </div>
      </main>
    </>
  );
};

export default AgencyAds;
