import React from "react";
import { ReactComponent as EditSvg } from "../../assets/svg/edit.svg";
import { ReactComponent as TrashSvg } from "../../assets/svg/trash-2.svg";
import { protectedHttp } from "../../helpers/http.helper";

const AllPartners = () => {
  const [partners, setPartners] = React.useState([]);
  const [partnerLink, setPartnerLink] = React.useState("");
  const [imageLink, setImageLink] = React.useState("");

  const [selectedPartner, setSelectedPartner] = React.useState(null);

  const getPartners = React.useCallback(async () => {
    try {
      const { data } = await protectedHttp.get("/admin/partner");
      setPartners(data.partners);
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    getPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPartner = async (e) => {
    e.preventDefault();
    try {
      await protectedHttp.post("/admin/partner", {
        imageLink,
        partnerLink,
      });
      getPartners();
      setPartnerLink("");
      setImageLink("");
    } catch (error) {
      console.log(error);
    }
  };

  const deletePartner = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      protectedHttp
        .delete(`/admin/partner/${id}`)
        .then(() => {
          getPartners();
        })
        .catch((err) => console.log(err.response.data.msg));
    }
  };

  const updatePartner = async (e) => {
    e.preventDefault();
    try {
      await protectedHttp.patch(`/admin/partner/${selectedPartner}`, {
        imageLink,
        partnerLink,
      });
      setSelectedPartner(null);
      setPartnerLink("");
      setImageLink("");
      getPartners();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <main>
        <div className="main-content">
          <div className="admin-top-bar-wrapper">
            <div className="admin-top-bar-container">
              <h1>All Partners</h1>

              {/* <button onClick={() => navigate("/admin/create")}>
                Create Ad <PlusSvg />
              </button> */}
            </div>
          </div>
          <div className="form-partners">
            <form onSubmit={selectedPartner ? (e) => updatePartner(e) : (e) => createPartner(e)}>
              <div className="input-wrapper">
                <input
                  onChange={(e) => setImageLink(e.target.value)}
                  type="text"
                  placeholder=""
                  id="name"
                  value={imageLink}
                />
                <label htmlFor="name" className="placeHolderLabel">
                  Image Link
                </label>
              </div>
              <div className="input-wrapper">
                <input
                  onChange={(e) => setPartnerLink(e.target.value)}
                  type="text"
                  placeholder=""
                  id="name"
                  value={partnerLink}
                />
                <label htmlFor="name" className="placeHolderLabel">
                  Partner Link
                </label>
              </div>
              <button type="submit" className="suc">
                {selectedPartner ? "Update Partner" : "Create Partner"}
              </button>
              <button
                onClick={() => {
                  setPartnerLink("");
                  setImageLink("");
                  setSelectedPartner(null);
                }}
                type="button"
                className="war"
              >
                Cancel
              </button>
            </form>
          </div>
          <div className="escort-card-wrapper">
            <div className="escort-card-container partners admin">
              {partners.map((partner) => (
                <div className="partner-card admin" key={partner._id}>
                  <a href={partner.partnerLink} target="_blank" rel="noreferrer">
                    <img src={partner.imageLink} alt="" />
                  </a>
                  <button
                    onClick={() => {
                      setSelectedPartner(partner._id);
                      setPartnerLink(partner.partnerLink);
                      setImageLink(partner.imageLink);
                    }}
                  >
                    <EditSvg /> Edit
                  </button>
                  <button onClick={() => deletePartner(partner._id)}>
                    <TrashSvg /> Delete
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AllPartners;
