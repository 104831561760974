import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import SignUp from "./SignUp";
import SignIn from "./SignIn";

const Auth = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (localStorage.getItem("uu_session")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      <Route path="/signup/*" element={<SignUp />} />
    </Routes>
  );
};

export default Auth;
