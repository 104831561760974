import React from "react";
import { ReactComponent as EditSvg } from "../../assets/svg/edit.svg";
import { ReactComponent as TrashSvg } from "../../assets/svg/trash-2.svg";
import { protectedHttp } from "../../helpers/http.helper";
import { countryArray } from "../../utils/data";
import { useNavigate } from "react-router-dom";

const Links = () => {
  const navigate = useNavigate();
  return (
    <>
      <main>
        <div className="main-content">
          <div className="admin-top-bar-wrapper">
            <div className="admin-top-bar-container">
              <h1>Links</h1>

              {/* <button onClick={() => navigate("/admin/create")}>
                Create Ad <PlusSvg />
              </button> */}
            </div>
          </div>
          <div className="escort-card-wrapper">
            <div className="escort-card-container partners admin">
              {countryArray.map((country) => (
                <div className="link admin">
                  <p>{country}</p>
                  <button onClick={() => navigate(`edit/${country}`)}>
                    <EditSvg />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Links;
