import React from "react";
import { ReactComponent as LeftSvg } from "../../assets/svg/arrow-left.svg";
import { ReactComponent as RemoveSvg } from "../../assets/svg/remove.svg";
import { useNavigate } from "react-router-dom";
import { BASE_URL, protectedHttp, protectedMediaHttp } from "../../helpers/http.helper";
import { ReactComponent as AddImageSvg } from "../../assets/svg/addimage.svg";

const AgencyProfile = () => {
  const navigate = useNavigate();
  const [id, setId] = React.useState("");
  const [displayName, setDisplayName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const selectMainImage = (e) => {
    setMainImage(e.target.files[0]);
    mainImageRef.current.value = "";
  };

  const mainImageRef = React.useRef(null);
  const [mainImage, setMainImage] = React.useState(null);

  const getProfile = async () => {
    try {
      const { data } = await protectedHttp.get("/auth/profile");
      const user = data.user;
      setId(user._id);
      setDisplayName(user.displayName);

      if (user.banner) {
        // set main image
        let imageUrl = `${BASE_URL}/agencies/${user.banner}`;
        let imageUrlFetched = await fetch(imageUrl);
        let blob = await imageUrlFetched.blob();
        let fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
        let file = new File([blob], fileName, { type: blob.type });
        setMainImage(file);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getProfile();
  }, []);

  const updateProfile = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("_id", id);
      formData.append("displayName", displayName);
      if (mainImage !== null) {
        formData.append("image", mainImage);
      }
      await protectedMediaHttp.post("/auth/update-agency", formData);

      alert("Profile updated successfully");
      getProfile();
    } catch (error) {
      console.log(error);
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      await protectedHttp.post("/auth/change-password", { password });
      alert("Password updated successfully");

      setPassword("");
      setConfirmPassword("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <main>
        <div className="main-content">
          <div className="admin-top-bar-wrapper">
            <div className="admin-top-bar-container with-back">
              <button onClick={() => navigate(-1)}>
                <LeftSvg />
              </button>
              <h3>Edit Profile</h3>
            </div>
          </div>
          <div className="escort-card-wrapper">
            <div className="form-container admin-add-form edit-agency">
              <form onSubmit={updateProfile}>
                <div className="right">
                  <h2>Edit details</h2>
                  <div className="row column-2 with-main-image">
                    <div className="main-image input-wrapper image">
                      {mainImage === null ? (
                        <div className="image-input">
                          <label htmlFor="mainImage">
                            <i className="svg-wrapper">
                              <AddImageSvg />
                            </i>
                            Add display image
                          </label>
                          <input
                            id="mainImage"
                            onChange={selectMainImage}
                            type="file"
                            multiple="multiple"
                            accept=".png, .jpg, .jpeg"
                            size="12582912"
                            ref={mainImageRef}
                          />
                        </div>
                      ) : (
                        <div className="image container">
                          <img src={URL.createObjectURL(mainImage)} alt={mainImage.name} />

                          <button type="button" onClick={() => setMainImage(null)}>
                            <i className="svg-wrapper">
                              <RemoveSvg />
                            </i>
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="input-wrapper">
                      <input
                        type="text"
                        placeholder="Display Name"
                        onChange={(e) => setDisplayName(e.target.value)}
                        id="name"
                        value={displayName}
                      />
                    </div>
                  </div>
                </div>

                <button type="submit" className="input-wrapper btn">
                  Update
                </button>
              </form>

              <form onSubmit={updatePassword}>
                <div className="right">
                  <h2>Change password</h2>
                  <div className="row column-2">
                    <div className="input-wrapper">
                      <input
                        type="password"
                        placeholder="New password"
                        onChange={(e) => setPassword(e.target.value)}
                        id="name"
                        value={password}
                      />
                    </div>
                    <div className="input-wrapper">
                      <input
                        type="password"
                        placeholder="Confirm password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        id="name"
                        value={confirmPassword}
                      />
                    </div>
                  </div>
                </div>

                <button type="submit" className="input-wrapper btn">
                  Change Password
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AgencyProfile;
