import React from "react";
import CommonTopBar from "../components/layouts/CommonTopBar";
import { LogoImage } from "../assets/images";
import { Helmet } from "react-helmet";
import Navigation from "../components/layouts/Navigation";
import FooterSection from "../components/layouts/FooterSection";

const Policy = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> {`Luxury Linkup - Privacy Policy`} </title>
        <meta name="title" content={`Luxury Linkup - Privacy Policy`} />

        {/* <!-- Open Graph Meta Tags --> */}
        <meta property="og:title" content={`Luxury Linkup - Privacy Policy`} />

        <meta property="og:image" content={LogoImage} />
        <meta property="og:url" content={`https://luxurylinkup.com/privacy-policy`} />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Luxury Linkup - Privacy Policy`} />

        <meta name="twitter:image" content={LogoImage} />
        <meta name="twitter:url" content={`https://luxurylinkup.com/privacy-policy`} />

        <link rel="canonical" href={`https://luxurylinkup.com/privacy-policy`} />
      </Helmet>
      <CommonTopBar selectedCountry="Privacy Policy" />
      <main>
        <div className="main-content">
          <div className="main-content-title-wrapper without-filters">
            <div className="main-content-title-container">
              <h1 className="selected-country-name">Privacy Policy</h1>
              <Navigation />
            </div>
          </div>
          {/* <h1 className="page-titles">Top 50 Escorts</h1> */}
          <div className="escort-card-wrapper">
            <div className="escort-card-container about">
              <div className="content">
                <h3>Privacy Policy</h3>
                <p>
                  Your privacy is important to us. It is Luxury Linkup's policy to respect your
                  privacy regarding any information we may collect from you across our website,{" "}
                  <a href="http://www.luxurylinkup.com">http://www.luxurylinkup.com</a>, and other
                  sites we own and operate.
                </p>

                <h2>Information We Collect</h2>
                <p>
                  We only collect information about you if we have a reason to do so - for example,
                  to provide our services, to communicate with you, or to make our services better.
                </p>

                <h2>How We Use Your Information</h2>
                <p>We use the information we collect in various ways, including to:</p>
                <ul>
                  <li>Provide, operate, and maintain our website</li>
                  <li>Improve, personalize, and expand our website</li>
                  <li>Understand and analyze how you use our website</li>
                  <li>Develop new products, services, features, and functionality</li>
                  <li>Communicate with you, either directly or through one of our partners</li>
                  <li>Send you emails</li>
                  <li>Find and prevent fraud</li>
                </ul>

                <h2>Security</h2>
                <p>
                  We value your trust in providing us your personal information, thus we are
                  striving to use commercially acceptable means of protecting it. But remember that
                  no method of transmission over the internet, or method of electronic storage is
                  100% secure and reliable, and we cannot guarantee its absolute security.
                </p>

                <h2>Changes to This Privacy Policy</h2>
                <p>
                  We may update our Privacy Policy from time to time. Thus, we advise you to review
                  this page periodically for any changes. We will notify you of any changes by
                  posting the new Privacy Policy on this page. These changes are effective
                  immediately after they are posted on this page.
                </p>

                <h2>Contact Us</h2>
                <p>
                  If you have any questions or suggestions about our Privacy Policy, do not hesitate
                  to contact us at <a href="mailto:info@luxurylinkup.com">info@luxurylinkup.com</a>.
                </p>
              </div>

              <div className="img-wrapper">
                <img src={LogoImage} alt="" />
              </div>
            </div>
          </div>

          <FooterSection />
        </div>
      </main>
    </>
  );
};

export default Policy;
